
import {newContract, ERC20} from "../web3";
import {getConstants} from './constants';

import I_LP from './ABI/LP.json'
import IFatory from './ABI/IFactory.json'

////// customize dapp ABI //////
import I_NODE from './ABI/INode.json'
import I_APP from './ABI/IApp.json'
import I_V2 from './ABI/IV2.json'


////// Native token //////
export const EthAddr = () => getConstants().ETH
export const WEthAddr = () => getConstants().WETH
export const Factorys = () => getConstants().ORACLE_FACTORYS
export const Oracle = () => getConstants().ORACLE
////// uesBalance //////
export const TokenAll = () => {
    const {
        BALANCE_LIST
    } = getConstants()
    const map = {}
    BALANCE_LIST.forEach(v => {
        map[v[2]] = v
    })
    return {
        list: BALANCE_LIST,
        map,
        erc20: tokenName => ERC20(map[tokenName][0])
    }
}


export const ChooseList = () => getConstants().BALANCE_LIST

// lp
export const Pair = address => newContract(address, I_LP)
export const Factory = address => newContract(address, IFatory)
////// token end //////

///// stake /////

export const LpPool = () => newContract(getConstants().LP_POOL, I_NODE)
export const NodePool = () => newContract(getConstants().NODE_POOL, I_NODE)
export const Appointment = () => newContract(getConstants().APPOINTMENT, I_APP)
export const V2 = () => newContract(getConstants().V2, I_V2)
export const TEST_U = () => getConstants().TEST_U
export const USDT = () => getConstants().USDT