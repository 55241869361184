
import { memo } from 'react';

import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import {
    // HashRouter as Router,
    // Route,
    // Link,
    useHistory
} from "react-router-dom";

import {
    Card,
    Container,
    TextMD,
    TextM,
    TextSM,
    ButtonA,
    WhiteSpace,
    // Input,
    Button,
    Hr,
    Icon,
    TextLinear,
    FlexBlock,
    Progress,
    // Hot,
    Tooltip,
    // APR,
    NoData,
    Hot,
    TipsImg
} from '../../components'

import SvgCircle from '../../pageComponents/SvgCircle'


// import StakeSeed from './StakeSeed'
// import StakeDialog from '../StakeDialog'

import useBalance from '../../hook/pages/useBalance'
import {useAppointment, useAppClaim} from '../../hook/pages/useAppointment'
import {useNode, useTotalBalance} from '../../hook/pages/useNode'

import { wFormatInt, splitNumber, BN } from 'web3-sam/dist/BigNumber';
// import {utils} from 'web3-sam'
// import {getAmountIn} from "../../utils/swap"


// import SvgCircle,{CircleArr} from '../../pageComponents/SvgCircle'

// import {useSmiCountdown} from '../../hook/useCountdown'

// import {
//     useStakeF,
//     useClaimF2,
//     useClaimF1
// } from '../../hook/pages/useStakeF'

// const { shortAddress } = utils

// function ClaimF1() {
//     return <Button size='0' {...useClaimF1()}/>
// }
// const ClaimF1Mome = memo(ClaimF1)

// function ClaimF2() {
//     return <Button size='0' {...useClaimF2()}/>
// }
// const ClaimF2Mome = memo(ClaimF2)


function Home() {
    const bNode = useTotalBalance()
    const {t} = useTranslation()
    
    const bal = useBalance()
    const oracle = bal.oracle(["AIX","USDT"])
    const {integer, decimalTail, decimal0} = splitNumber(oracle.price)
    
    const swapTo = `https://arbswap.io/swap?inputCurrency=${bal.token("USDT")[0]}&chainIn=42161&outputCurrency=${bal.token("AIX")[0]}&chainOut=42161`
    return (
        <Container>
            <FlexBlock flex>
                <div decorate='1' style={{textAlign: 'left'}}>
                    <Tooltip
                        title={
                            <>
                                <TextSM style={{marginBottom: "6px"}}>AIX {wFormatInt(oracle.lp[0][0])}</TextSM>
                                <br />
                                <TextSM>USDT {wFormatInt(oracle.lp[0][1])}</TextSM>
                            </>
                        }
                        placement="right"
                        arrow
                    >
                        <TextSM>AIX {t("Price")}. <Icon size='12' type='icon-info-fill' /></TextSM>
                    </Tooltip> 
                    {/* <TextLinear size='0'>FY / USDT</TextLinear> */}
                    {/* <TextSM color='2'>AIX Price.</TextSM> */}
                    <br />
                    {/* <TextLinear size='3'>{wFormatInt(integer)}.{decimal0 !== 0 ? <sup style={{fontSize: '20px'}}>{decimal0}</sup> : null }{(decimalTail.slice(0,4)*1)}</TextLinear> */}
                    <TextLinear size='3'>{wFormatInt(integer)}.{decimal0 !== 0 ? <sup style={{fontSize: '20px'}}>{decimal0}</sup> : null }{(decimalTail.slice(0,4))}</TextLinear>
                </div>
                <div>
                    <Button style={{marginRight:'8px'}} status='2' size='0' onClick={() => bal.copyLink('appointment')}>{t("Share")} {t("Link")}</Button>
                    <ButtonA size='0' href={swapTo}>
                        {t("Swap")}
                    </ButtonA>
                </div>
            </FlexBlock>
            {/* <div style={{textAlign: 'left'}}>
                <TextGL>AI ETH</TextGL>
                <br />
                <TextSM color='2'>Joint mining to earn Cake.</TextSM>
            </div>
            <WhiteSpace size='2'/> */}
            <WhiteSpace />
            <Card>
                <TextSM>{t("Node")} {t("Balance")}</TextSM>
                <br />
                <TextLinear>{bNode.lpBalance} </TextLinear><TextSM color='2' style={{marginLeft: '6px'}}> USDT</TextSM>
            </Card>
            <WhiteSpace />
            <Appointment />
            <WhiteSpace />
            <Node />
        </Container>
    )
}
export default Home


function Appointment() {
    const {t} = useTranslation()
    const app = useAppointment()
    const claimButton = useAppClaim()
    const history = useHistory()
    return (
        <Card>
            <TextM>{t("Appointment")}</TextM>
            <br />
            <TextSM color='2'>{t("Rate")} 100 U = 100,000,000 AIX</TextSM>
            <WhiteSpace />
            <Hr />
            <WhiteSpace />
            <FlexBlock flex>
                <div>
                    <TextSM color='2'>{t("Share")} {t("Reward")}</TextSM>
                    <br />
                    <TextM>{app.claim} <TextSM color='2'>/ USDT</TextSM></TextM>
                </div>
                <Button size='0' status='2' {...claimButton}/>
            </FlexBlock>
            <WhiteSpace />
            <FlexBlock flex>
                <TextSM color='2'>{t("My")} {t("Balance")} <TextLinear size='0' linear='1'>{wFormatInt(app.balances)}</TextLinear> AIX</TextSM>
                <Button size='0' status='3' onClick={() => history.push('/appointment')}>{t("Appoint")}</Button>
            </FlexBlock>
        </Card>
    )
}

function CircleBlock({per = 100}) {
    return (
        <div style={{position: 'relative'}}>
            <SvgCircle per={per}/>
            <CText >{per}%</CText>
        </div>
        
    )
}

function Node() {
    const {lp, node} = useNode()
    return (
        <>
            <LpPool node={lp} />
            <WhiteSpace />
            <NodePool node={node} />
        </>
    )
}

function LpPool({node}) {
    const {t} = useTranslation()
    const history = useHistory()
    // console.log(
    //     node
    // )
    return (
        <Card>
            <TextM>{t("Lp")} {t("Pool")}</TextM>
            <br />
            <TextSM color='2'>{t("Participating in the Lp Pool can yield x3 returns.")}</TextSM>
            <WhiteSpace />
            <Hr />
            <WhiteSpace />
            <FlexBlock flex justify='start'>
                <CircleBlock per={node.earnedReate}/>
                <div style={{marginLeft:'8px'}}>
                    <TextLinear size='1'>#{node.earnId}</TextLinear>
                    <br />
                    <TextSM color='2'>{t("Earned")} <TextLinear size='1' linear='1'>{wFormatInt(node.earned)}</TextLinear> USDT</TextSM>
                    <br />
                    <TextSM color='2'>{t("Total")} {t("Staked")} <TextSM>{wFormatInt(node.totalEarn)}</TextSM> USDT</TextSM>
                </div>
            </FlexBlock>
            <WhiteSpace />
            <Hr />
            <WhiteSpace />
            <FlexBlock flex>
                <div>
                    <TextSM color='2'>{t("Share")} {t("Reward")}</TextSM>
                    <br />
                    <TextM>{node.claim} <TextSM color='2'>/ USDT</TextSM></TextM>
                    <br />
                    <TextSM color='2'>{t("My")} {t("Staked")} <TextSM>{node.staked}</TextSM> USDT</TextSM>
                </div>
                <Button size='0' status='3' onClick={() => history.push('/lp')}>{t("Detail")}</Button>
            </FlexBlock>
        </Card>
    )
}

function NodePool({node}) {
    const history = useHistory()
    const {t} = useTranslation()

    return (
        <Card>
            <TextM>{t("Node")} {t("Pool")}</TextM>
            <br />
            <TextSM color='2'>{t("Participating in the Node Pool can yield x3 returns.")}</TextSM>
            <WhiteSpace />
            <Hr />
            <WhiteSpace />
            <FlexBlock flex justify='start'>
                <CircleBlock per={node.earnedReate}/>
                <div style={{marginLeft:'8px'}}>
                    <TextLinear size='1'>#{node.earnId}</TextLinear>
                    <br />
                    <TextSM color='2'>{t("Earned")} <TextLinear size='1' linear='1'>{wFormatInt(node.earned)}</TextLinear> USDT</TextSM>
                    <br />
                    <TextSM color='2'>{t("Total")} {t("Staked")} <TextSM>{wFormatInt(node.totalEarn)}</TextSM> USDT</TextSM>
                </div>
            </FlexBlock>
            <WhiteSpace />
            <Hr />
            <WhiteSpace />
            <FlexBlock flex>
                <div>
                    <TextSM color='2'>{t("Share")} {t("Reward")}</TextSM>
                    <br />
                    <TextM>{node.claim} <TextSM color='2'>/ USDT</TextSM></TextM>
                    <br />
                    <TextSM color='2'>{t("My")} {t("Staked")} <TextSM>{node.staked}</TextSM> USDT</TextSM>
                </div>
                <Button size='0' status='3' onClick={() => history.push('/node')}>{t("Detail")}</Button>
            </FlexBlock>
        </Card>
    )
}


const CText = styled(TextM)`
    position: absolute;
    text-align: center;
    line-height: 40px;
    width: 80%;
    top: 50%;
    left: 10%;
    margin-top: -22px;
`