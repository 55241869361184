
import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

// import styled from 'styled-components';

// import {
//     // HashRouter as Router,
//     // Route,
//     // Link,
//     useHistory
// } from "react-router-dom";

import {
    Card,
    Container,
    TextMD,
    TextM,
    TextSM,
    TextGL,
    WhiteSpace,
    Input,
    Button,
    Hr,
    Icon,
    TextLinear,
    FlexBlock,
    Progress,
    // Hot,
    Tooltip,
    // APR,
    NoData,
    Hot,
    TipsImg
} from '../../components'

import SvgCircle from '../../pageComponents/SvgCircle'


// import StakeSeed from './StakeSeed'
// import StakeDialog from '../StakeDialog'

import useBalance from '../../hook/pages/useBalance'

import {
    useApplyLp,
    useNode,
    useApplyNode,
    useClaimLp,
    useClaimNode
} from '../../hook/pages/useNode'
// import {
//     useInitLiquidity,
//     useInitPurchase,
//     useLpClaim
// } from '../../hook/pages/usePurchase'
// dialog 
import Approve from '../../contractComponents/Approve'

// import useInput from '../../hook/useInput'

// import useCopy from '../../hook/useCopy'

import { wFormatInt, splitNumber, BN } from 'web3-sam/dist/BigNumber';
import {utils} from 'web3-sam'
import {getAmountIn} from "../../utils/swap"


// import SvgCircle,{CircleArr} from '../../pageComponents/SvgCircle'

// import {useSmiCountdown} from '../../hook/useCountdown'

// import {
//     useStakeF,
//     useClaimF2,
//     useClaimF1
// } from '../../hook/pages/useStakeF'

const { shortAddress } = utils

// function ClaimF1() {
//     return <Button size='0' {...useClaimF1()}/>
// }
// const ClaimF1Mome = memo(ClaimF1)

// function ClaimF2() {
//     return <Button size='0' {...useClaimF2()}/>
// }
// const ClaimF2Mome = memo(ClaimF2)


function LpPool() {
    const {t} = useTranslation()
    const bal = useBalance()
    const {lp} = useNode()
    const claimButton = useClaimLp()
    const {
        stakeAmount,
        button
    } = useApplyLp()
    const disabled = stakeAmount.value === '' || stakeAmount.value * 1 < 100 || stakeAmount.value * 1 > 1000
    return (
        <Container align='left'>
            <TextMD>{t("Lp")} {t("Pool")}</TextMD>
            <WhiteSpace />
            <Card>
                <FlexBlock flex>
                    <div>
                        <TextSM color='2'>{t("Share")} {t("Reward")}</TextSM>
                        <br />
                        <TextM>{lp.claim} <TextSM color='2'>/ USDT</TextSM></TextM>
                    </div>
                    <Button size='0' status='2' {...claimButton} />
                </FlexBlock>
                <WhiteSpace />
                <Hr />
                <WhiteSpace />
                <TextSM color='2'>{t("Balance")} <TextLinear size='0'>{bal.balance.USDT}</TextLinear> USDT</TextSM>
                <WhiteSpace />
                <Input input={stakeAmount}/>
                <WhiteSpace />
                <TextSM color='2'>{t("Expect")} ID <TextSM>#{lp.pSize * 1 + 1}</TextSM></TextSM>
                <WhiteSpace />
                <Approve w='100' {...button} disabled={disabled}/>
            </Card>
            <WhiteSpace />
            <TextSM>My Position</TextSM>
            <WhiteSpace />
            <Card>
                {
                    lp.position.length === 0 ? <NoData /> :
                    lp.position.map((item) => (
                        <div key={item.id} style={{marginBottom:'10px'}}>
                            <FlexBlock justify='start' flex>
                                <TextM style={{marginRight: '16px'}}>#{item.id*1 + 1}</TextM>
                                <div>
                                    <TextSM color='2'>{t("Reward")} <TextLinear size='0'>{item.claimed}</TextLinear> U</TextSM>
                                    <br />
                                    <TextSM color='2'>{t("Staked")} <TextSM>{item.staked}</TextSM> U</TextSM>
                                </div>
                            </FlexBlock>
                            <WhiteSpace />
                            <Hr />
                        </div>
                    ))
                }
            </Card>
        </Container>
    )
}
export default LpPool


function NodePool() {
    const {t} = useTranslation()
    const bal = useBalance()
    const {node} = useNode()
    const {
        button,
        refferrer
    } = useApplyNode()
    const claimButton = useClaimNode()


    useEffect(() => {
        if (node.applied) {
            refferrer.onChange(node.refferrer)
        }
    }, [node.refferrer, node.applied])
    

    const disabled = bal.balance.USDT * 1 < 1000
    return (
        <Container align='left'>
            <TextMD>{t("Node")} {t("Pool")}</TextMD>
            <WhiteSpace />
            <Card>
                <FlexBlock flex>
                    <div>
                        <TextSM color='2'>{t("Share")} {t("Reward")}</TextSM>
                        <br />
                        <TextM>{node.claim} <TextSM color='2'>/ USDT</TextSM></TextM>
                    </div>
                    <Button size='0' status='2' {...claimButton} />
                </FlexBlock>
                <WhiteSpace />
                <Hr />
                <WhiteSpace />
                <TextSM color='2'>{t("Balance")} <TextLinear size='0'>{bal.balance.USDT}</TextLinear> USDT</TextSM>
                <WhiteSpace />
                <TextSM color='2'>{t("Each pledge costs")} 1000 U.</TextSM>
                <WhiteSpace />
                {
                    !node.applied &&
                    <>
                        <TextSM color='2'>{t("Recommender")}</TextSM>
                        <WhiteSpace />
                        <Input
                            input={{
                                ...refferrer,
                                disabled: node.applied
                            }}
                        />
                    </>
                }
                <WhiteSpace />
                <TextSM color='2'>{t("Expect")} ID <TextSM>#{node.pSize * 1 + 1}</TextSM></TextSM>
                <WhiteSpace />
                {
                    disabled ? <><TextSM style={{color:'red'}}>{t("Need")} 1000 U</TextSM></> : null
                }
                {/* <Approve w='100' {...button} disabled={disabled}/> */}
                <Approve w='100' {...button} />
            </Card>
            <WhiteSpace />
            <TextSM>{t("My")} {t("Position")}</TextSM>
            <WhiteSpace />
            <Card>
                {
                    node.position.length === 0 ? <NoData /> :
                    node.position.map((item) => (
                        <div key={item.id} style={{marginBottom:'10px'}}>
                            <FlexBlock justify='start' flex>
                                <TextM style={{marginRight: '16px'}}>#{item.id*1 + 1}</TextM>
                                <div>
                                    <TextSM color='2'>{t("Reward")} <TextLinear size='0'>{item.claimed}</TextLinear> U</TextSM>
                                    <br />
                                    <TextSM color='2'>{t("Staked")} <TextSM>{item.staked}</TextSM> U</TextSM>
                                </div>
                            </FlexBlock>
                            <WhiteSpace />
                            <Hr />
                        </div>
                    ))
                }
            </Card>
        </Container>
    )
}
export {
    NodePool
}