export const inPc = (() => {
    var sUserAgent = navigator.userAgent;
    if (sUserAgent.indexOf('Android') > -1 || sUserAgent.indexOf('iPhone') > -1 || sUserAgent.indexOf('iPad') > -1 || sUserAgent.indexOf('iPod') > -1 || sUserAgent.indexOf('Symbian') > -1) {
        return false
    } else {
        return true
    }
})();

function linearGradient(deg, start, stop) {
    return `linear-gradient(${deg}deg,  ${start}, ${stop})`
}

// function shadow(color) {
//     return `0px 0px .4rem ${color}`
// }


// card
// block input
// text
// button
// alert
// draw

// size
// background
// border

const background = [
    {
        // #7fc248,#63bb35
        // background: 'linear-gradient(45deg,rgb(252 255 244),rgb(255 247 238 / 88%))',
        background: 'linear-gradient(360deg, rgb(32 32 32 / 98%), rgb(36 35 35 / 98%))',
        
        // backgroundImage: 'url(./.png)',
        // border: '1rem solid transparent',
    },
    {
        // #7fc248,#63bb35
        background: 'linear-gradient(45deg, #00E0B8, #006CE6)'
    },
    {
        background: 'linear-gradient(45deg, rgb(255 109 109 / 95%), rgb(232 80 0 / 95%))'
    },
    { 
        background: `linear-gradient(45deg, rgb(163 255 119), rgb(111 225 0))`
    },
    {
        background: 'linear-gradient(122deg, #FFD84F, #FFED8F)'
    },
    {
        background: 'linear-gradient(122deg, #00E0B8, #006CE6)'
    },
    {
        background: '#1d1c1c',
        color: '#fff !important',
        border: '1px solid #565656'
    },
    {
        background: `linear-gradient(136deg,#fee8b2,#ffbcbc)`,
        // border: '0.1rem solid transparent',
        backgroundClip: 'padding-box, border-box',
        backgroundOrigin: 'padding-box, border-box'
    },
    {
        // #7fc248,#63bb35
        background: '#000',
        // backgroundImage: 'url(./.png)',
        // border: '1rem solid transparent',
    },
]

const color = [
    '#FFFFFF',
    '#000',
    '#a4a39e',
    '#FED215',
    'rgb(82 180 183)',
    '#9da5ad',
    "#4B5563",
    '#6B7280',
    '#292D32',
    '#9CA3AF',
];

const size = new Array(7).fill(1.3).map((v,i) => v * (1.382**i));




const theme = {
    // public
    MAX_WIDTH: 1080,
    isPc: inPc,
    isV2: window.location.hostname.split(".")[0] !== 'v3',
    bodyBack: 'rgb(0, 0, 0)',
    tableBackground: '#f2f2f2',
    footerHeight: '5.2rem',
    borderRadius: '18px',
    boxShadow: '0px 0px 6px rgba(0,0,0,.2)',
    active: '#F3503C',
    testLinear: [`background: linear-gradient(45deg, rgb(0 213 189), rgb(52 116 170))`, 'background: linear-gradient(120deg,#ff8a38,#ff5400,#ff8a38)'],
    hrBackground: 'linear-gradient(120deg, rgb(124 37 255), rgb(48 100 255), rgb(78 35 143))',
    headBarBackground: '#000',
    // font
    size,
    padding: size.map(v => `${v * 0.618}rem ${v}rem`),
    // 由亮 到 暗
    // 字体颜色
    color,
    buttonSize: [
        // small
        {
            fontSize: '1rem',
            padding: '.4rem .8rem',
            // borderRadius: '0rem',
        },
        {
            fontSize: '1.4rem',
            padding: '1rem 1.4rem',
        },
        {
            fontSize: '1.8rem',
            padding: '1.4rem 1.8rem',
        },
        {
            fontSize: '2.6rem',
            padding: '1.4rem 1.8rem',
        },
        {
            fontSize: '3rem',
            padding: '1.4rem 1.8rem',
        },
    ],
    // 由 亮 变 暗
    block: [
        background[0],
        background[1],
        background[2],
        background[3],
        background[4],
        background[5],
        background[6],
        background[7],
        background[8],
    ],
    draw: background[2],
    // 由 亮 变 暗
    button: [
        // normal
        {
            ...background[5],
            color: color[1]
        },
        // normalA
        {
            ...background[2],
            color: color[1]
        },
        // normalB
        {
            ...background[3],
            color: color[1]
        },
        // border
        {
            ...background[6],
            // color: color[1]
        },
        // disabled
        {
            background: '#000',
            color: 'rgb(106 106 106) !important',
            border: '1px solid #565656'
        },
        // active
        {
            ...background[7],
            color: color[1]
        },
        // un active
        {
            ...background[6],
            color: color[1]
        },
        // ...other
    ],
    squareDot: [
        background[8],
        background[9]
    ]
}

export default theme