
import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

// import styled from 'styled-components';

// import {
//     // HashRouter as Router,
//     // Route,
//     // Link,
//     useHistory
// } from "react-router-dom";

import {
    Card,
    Container,
    TextMD,
    TextM,
    TextSM,
    TextGL,
    WhiteSpace,
    Input,
    Button,
    Hr,
    Icon,
    TextLinear,
    FlexBlock,
    Progress,
    // Hot,
    Tooltip,
    // APR,
    NoData,
    Hot,
    TipsImg
} from '../../components'

// import SvgCircle from '../../pageComponents/SvgCircle'
import Approve from '../../contractComponents/Approve'

// import StakeSeed from './StakeSeed'
// import StakeDialog from '../StakeDialog'
import useCopy from '../../hook/useCopy'

import useBalance from '../../hook/pages/useBalance'
import {useAppointment, useAppApply} from '../../hook/pages/useAppointment'

import { wFormatInt, splitNumber, BN } from 'web3-sam/dist/BigNumber';
import {utils} from 'web3-sam'
import { ZERO_ADDRESS } from 'web3-sam/dist/config';

import {getUrlParams} from "../../utils"

const { shortAddress } = utils

// function ClaimF1() {
//     return <Button size='0' {...useClaimF1()}/>
// }
// const ClaimF1Mome = memo(ClaimF1)

// function ClaimF2() {
//     return <Button size='0' {...useClaimF2()}/>
// }
// const ClaimF2Mome = memo(ClaimF2)


function Appointment() {
    const {t} = useTranslation()
    const bal = useBalance()
    const app = useAppointment()
    const copy = useCopy()
    const {
        deposit,
        recommender,
        button
    } = useAppApply()

    useEffect(() => {
        if (app.recommender !== ZERO_ADDRESS) {
            recommender.onChange(app.recommender)
        } else {
            const {r} = getUrlParams()
            if (r) {
                recommender.onChange(r)
            }
        }
    },[app.recommender])
    // console.log(app)
    return (
        <Container align='left'>
            <TextMD>{t('Appointment')}</TextMD>
            <WhiteSpace />
            <Card>
                <FlexBlock flex>
                    <div>
                        <TextSM color='2'>{t('Share')} {t('Reward')}</TextSM>
                        <br />
                        <TextM>{app.claim} <TextSM color='2'>/ USDT</TextSM></TextM>
                    </div>
                    <Button size='0' status='2'>{t('Claim')}</Button>
                </FlexBlock>
                <WhiteSpace />
                <Hr />
                <WhiteSpace />
                <TextSM color='2'>{t('Balance')} <TextLinear size='0'>{bal.balance.USDT}</TextLinear> U</TextSM>
                <WhiteSpace />
                <Input
                    input={deposit}
                />
                <WhiteSpace size='1'/>
                {/* <FlexBlock flex>
                    <TextSM color='2'>Recommender</TextSM>
                    <TextSM color='2'>Balance <TextLinear size='0'>{bal.balance.USDT}</TextLinear> U</TextSM>
                </FlexBlock> */}
                {
                    !app.applied &&
                    <>
                        <TextSM color='2'>{t('Recommender')}</TextSM>
                        <WhiteSpace />
                        <Input
                            input={{
                                ...recommender,
                                disabled: app.applied
                            }}
                        />
                    </>
                }
                <WhiteSpace />
                <TextSM color='2'>{t('My')} {t('Balance')} <TextSM>{wFormatInt(app.balances)}</TextSM> AIX</TextSM>
                <WhiteSpace />
                {/* {
                    app.applied ?
                    <Button w='100' disabled>Applied</Button>
                    :
                    <Approve w='100' {...button} />
                } */}
                <Approve w='100' {...button} />
            </Card>
            <WhiteSpace />
            <TextSM>{t('My')} {t('Share')} [{app.introduces.length}]</TextSM>
            <WhiteSpace />
            <Card>
                <WhiteSpace />
                {
                    app.introduces.length === 0 ?
                    <NoData />:
                    app.introduces.map((item) => (
                        <div key={item.address} style={{marginBottom:'10px'}} onClick={() => copy(item.address)}>
                            <TextSM color='2'>{(shortAddress(item.address, 10))} <Icon size='12' type="icon-copy"/></TextSM>
                            <WhiteSpace />
                            <Hr />
                        </div>
                    ))
                }
            </Card>
        </Container>
    )
}
export default Appointment