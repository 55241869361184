
import { memo } from 'react';

// import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import {
    // HashRouter as Router,
    // Route,
    // Link,
    useHistory
} from "react-router-dom";

import {
    Card,
    Container,
    TextMD,
    TextM,
    TextSM,
    TextGL,
    WhiteSpace,
    Input,
    Button,
    Hr,
    Icon,
    TextLinear,
    FlexBlock,
    // Progress,
    // Hot,
    Tooltip,
    // APR,
    NoData,
    Hot,
    TipsImg
} from '../../components'


// import StakeSeed from './StakeSeed'
// import StakeDialog from '../StakeDialog'
// import Swap, {AddLiquidity} from './Swap'

import useBalance from '../../hook/pages/useBalance'

// dialog 
import useDialog, {useDialogContext, Dialog2} from '../../hook/useDialog'
// import useInput from '../../hook/useInput'

// import useCopy from '../../hook/useCopy'

import { wFormatInt, splitNumber, BN } from 'web3-sam/dist/BigNumber';
import {utils} from 'web3-sam'
import {getAmountIn} from "../../utils/swap"

// import SvgCircle,{CircleArr} from '../../pageComponents/SvgCircle'

// import {useSmiCountdown} from '../../hook/useCountdown'

// import {
//     useStakeF,
//     useClaimF2,
//     useClaimF1
// } from '../../hook/pages/useStakeF'

const { shortAddress } = utils

// function ClaimF1() {
//     return <Button size='0' {...useClaimF1()}/>
// }
// const ClaimF1Mome = memo(ClaimF1)

// function ClaimF2() {
//     return <Button size='0' {...useClaimF2()}/>
// }
// const ClaimF2Mome = memo(ClaimF2)


function Footer() {
    return (
        <FooterBlock style={{textAlign:'left'}}>
            <FooterImg style={{bottom: '-20px', right:'-40px'}} src="./brains.png" />
            <FooterImg style={{top: '450px', left: '-40px'}} src="./aiNode.png" />
            <FooterImg style={{top: '90px', right:'-40px'}} src="./brain.png" />
        </FooterBlock>
    )
}
export default Footer

const FooterBlock = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -0;
    overflow: hidden;
`

const FooterImg = styled.img`
    width: 192px;
    /* height: 192px; */
    position: absolute;
`

const LinkA = styled.a`
    display: block;
    padding: 4px;
    margin-right: 2px;
    opacity: 0.6;
`