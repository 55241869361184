import { useState, useEffect } from "react";

import styled from "styled-components";

import AnimatedNumbers from "react-animated-numbers";

import {
    TextLinear
} from '../../components'
// function RollingN() {
//     return (

//     )
// }

function Rolling() {
    const [num, setNum] = useState(331231);
    return (
        <AnimatedNumbers
            includeComma
            animateToNumber={num}
            fontStyle={{ fontSize: 40, color: "#ddd" }}
            locale="en-US"
            configs={[
                { mass: 1, tension: 220, friction: 100 },
                { mass: 1, tension: 180, friction: 130 },
                { mass: 1, tension: 280, friction: 90 },
                { mass: 1, tension: 180, friction: 135 },
                { mass: 1, tension: 260, friction: 100 },
                { mass: 1, tension: 210, friction: 180 },
            ]}
        ></AnimatedNumbers>
    )
}

// function 
export default Rolling;

const RollingNumber = styled.div`
    /* position: relative; */
    display: inline-block;
    overflow: hidden;
    width: min-content;
   
    /* z-index: 1; */
    /* span {
        color: #fff;
    } */
`;