// Relation
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from 'react-i18next';

// import copy from 'copy-to-clipboard'

import {
    Appointment,
    TokenAll
} from "../../contract/contract";

import { useWeb3, initWeb3, BN, multiCalls, SendOn, utils, ZERO_ADDRESS , SendLocalOn} from '../../web3'


import {getUrlParams} from '../../utils'

import useBalance from './useBalance'


import initAsyncData from '../initAsyncData'

import useInput from '../useInput'
import useButton from "./useButton"


const INIT = {
    balances: 0,
    claim: 0,
    applied: true,
    introduces: [],
    recommender: ZERO_ADDRESS,
    hadRecommend: false
}
// function positionOf(address) external view returns(Position memory);
// function deposit(uint, address) external;
// function claimFor(address) external returns(uint);
// function introducers(address) external view returns(address[] memory, Position[] memory);
// if change chainId, clear regList
async function getInit(account) {
    const app = Appointment()
    
    const calls = await multiCalls({
        balances: app.methods.positionOf(account),
        claim: app.methods.claimFor(account),
        introduce: app.methods.introducers(account),
        recommender: app.methods.introducer(account),
    })
    const recommender = calls.recommender
    const hadRecommend = recommender !== ZERO_ADDRESS
    const balances = BN(calls.balances.balance).div(1e6).toString()
    const applied = balances * 1 > 0

    const claim = BN(calls.claim).div(1e6).toString()

    const introduces = calls.introduce[0].map((item, index) => {
        return {
            address: item,
            balance: BN(calls.introduce[1][index].balance).div(1e6).toString()
        }
    })
    return {
        balances,
        claim,
        applied,
        recommender,
        introduces,
        hadRecommend
    }
}


export function useAppointment() {
    const {account, getBlockNumber} = useWeb3()
    const [data, setData] = useState(INIT)
    const blockNubmer = getBlockNumber()

    useEffect(() => {
        // console.log({account, blockNubmer})
        initAsyncData(() => getInit(account), setData)
    },[account, blockNubmer])
    return {
        ...data,
        // copyLink
    }
}


export function useAppApply() {
    const {t} = useTranslation()

    const deposit = useInput('100', {type: 'number'})
    const recommender = useInput('')

    const {
        sender,
        coins
    } = useMemo(() => {
        const {map: {USDT}} = TokenAll()
        const sender = Appointment()._address
        const coins = [
            [...USDT, 10000, true]
        ]
        return {
            sender,
            coins
        }
    },[])

    const button = useButton(t('Apply'), {
        approve: {
            sender,
            coins
        },
        send() {
            const app = Appointment()
            const recom = recommender.value === '' ? ZERO_ADDRESS : recommender.value
            const depositWei = BN(deposit.value).multipliedBy(1e6).dp(0,1).toString()
            return app.methods.deposit(depositWei, recom)
        }
    })

    return {
        deposit,
        recommender,
        button
    }
}

export function useAppClaim() {
    const {account} = useWeb3()
    const {t} = useTranslation()
    const button = useButton(t("Claim"), {
        send() {
            const app = Appointment()
            return app.methods.claimFor(account)
        }
    })
    return button
}