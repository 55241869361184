// Relation
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from 'react-i18next';

// import copy from 'copy-to-clipboard'

import {
    LpPool,
    NodePool,
    Appointment,
    TokenAll
} from "../../contract/contract";

import { useWeb3, initWeb3, BN, multiCalls, SendOn, utils, ZERO_ADDRESS , SendLocalOn} from '../../web3'


// import {getUrlParams} from '../../utils'

// import useBalance from './useBalance'


import initAsyncData from '../initAsyncData'

import useInput from '../useInput'
import useButton from "./useButton"


const INIT = {
    lp: {
        pSize: 0,
        earnId: 0,
        earned: 0,
        earnedReate: 0,
        totalEarn: 1,
        claim: 0,
        staked: 0,
        position: []
    },
    node: {
        pSize: 0,
        earnId: 0,
        earned: 0,
        earnedReate: 0,
        totalEarn: 1,
        claim: 0,
        staked: 0,
        refferrer: ZERO_ADDRESS,
        isBind: false,
        position: []
    }
}

// function positions() external view returns(Position[] memory);
// function earningPosition() external view returns(Position memory);
// function earnPid() external view returns(uint);
// function positionSize() external view returns(uint256);
// function claimFor(address) external returns(uint, Position[] memory);
// function stake(uint) external;
// if change chainId, clear regList
async function getInit(account) {
    const lpPool = LpPool()
    const nodePool = NodePool()
    const appointment = Appointment()
    const calls = await multiCalls({
        lp: {
            addEarn: lpPool.methods.addEarn(),
            pSize: lpPool.methods.positionSize(),
            earnId: lpPool.methods.earnPid(),
            earned: lpPool.methods.earningPosition(),
            claim: lpPool.methods.claimFor(account),
            pIds: lpPool.methods.pIds(account),
        },
        node: {
            refferrer: appointment.methods.introducer(account),
            addEarn: nodePool.methods.addEarn(),
            pSize: nodePool.methods.positionSize(),
            earnId: nodePool.methods.earnPid(),
            earned: nodePool.methods.earningPosition(),
            claim: nodePool.methods.claimFor(account),
            pIds: nodePool.methods.pIds(account),
        }
    })
    
    // console.log(calls)
    // calls.lp.claim = [0]
    const lp = {}
    lp.pSize = calls.lp.pSize
    lp.earnId = calls.lp.earnId * 1 + 1
    const lpEarned = calls.lp.earned
    lp.earned = BN(lpEarned.earned).div(1e6).toString()
    lp.totalEarn = BN(lpEarned.totalEarn).div(1e6).toString()
    if ( lp.totalEarn === '0' ) {
        lp.earnId = 0
    }
    lp.claim = BN(calls.lp.claim[0]).div(1e6).toString()
    // earnedReate: 0,
    lp.earnedReate = lpEarned.totalEarn === '0' ? 0 : BN(lpEarned.earned).div(lpEarned.totalEarn).mul(100).dp(0,1).toString()
    let totalStaked = BN(0)
    lp.position = calls.lp.claim[1].map((item, i) => {
        totalStaked = totalStaked.add(item.totalEarn)
        const claimed = BN(item.claimed).div(1e6).toString()
        const staked = BN(item.totalEarn).div(3e6).toString()
        const id = calls.lp.pIds[i]
        return {
            id,
            claimed,
            staked
        }
    })
    lp.staked = totalStaked.div(3e6).toString()
    // pSize: 0,
    // earnId: 0,
    // earned: 0,
    // totalEarn: 1,
    // claim: 0,
    // staked: 0,
    // position: []
    const node = {}
    node.pSize = calls.node.pSize
    node.earnId = calls.node.earnId * 1 + 1
    const nodeEarned = calls.node.earned
    node.earned = BN(nodeEarned.earned).div(1e6).toString()
    node.totalEarn = BN(nodeEarned.totalEarn).div(1e6).toString()
    if ( node.totalEarn === '0' ) {
        node.earnId = 0
    }
    node.claim = BN(calls.node.claim[0]).div(1e6).toString()
    // earnedReate: 0,
    node.earnedReate = nodeEarned.totalEarn === '0' ? 0 : BN(nodeEarned.earned).div(nodeEarned.totalEarn).mul(100).dp(0,1).toString()
    let nodeTotalStaked = BN(0)
    node.position = calls.node.claim[1].map((item, i) => {
        nodeTotalStaked = nodeTotalStaked.add(item.totalEarn)
        const claimed = BN(item.claimed).div(1e6).toString()
        const staked = BN(item.totalEarn).div(3e6).toString()
        const id = calls.node.pIds[i]
        return {
            id,
            claimed,
            staked
        }
    })
    node.staked = nodeTotalStaked.div(3e6).toString()
    node.refferrer = calls.node.refferrer
    node.applied = node.refferrer !== ZERO_ADDRESS
    return {
        lp,
        node
    }
    // return INIT
}


export function useNode() {
    const {account, getBlockNumber} = useWeb3()
    const [data, setData] = useState(INIT)
    const blockNubmer = getBlockNumber()

    useEffect(() => {
        // console.log({account, blockNubmer})
        initAsyncData(() => getInit(account), setData)
    },[account, blockNubmer])
    return {
        ...data,
        // copyLink
    }
}


export function useApplyLp() {
    const stakeAmount = useInput("", {type:'number'})
    const {t} = useTranslation()
    const {
        sender,
        coins
    } = useMemo(() => {
        const {map: {USDT}} = TokenAll()
        const sender = LpPool()._address
        const coins = [
            [...USDT, 10000, true]
        ]
        return {
            sender,
            coins
        }
    },[])

    const button = useButton(t('Add Position'), {
        approve: {
            sender,
            coins
        },
        send() {
            const node = LpPool()
            const stakeWei = stakeAmount.value === '' ? 0 : BN(stakeAmount.value).mul(1e6).dp(0,1).toString()
            return node.methods.stake(stakeWei)
        }
    })
    return {
        stakeAmount,
        button
    }
}


export function useApplyNode() {
    const {t} = useTranslation()

    const refferrer = useInput("")
    const {
        sender,
        coins
    } = useMemo(() => {
        const {map: {USDT}} = TokenAll()
        const sender = NodePool()._address
        const coins = [
            [...USDT, 10000, true]
        ]
        return {
            sender,
            coins
        }
    },[])

    const button = useButton(t('Add Position'), {
        approve: {
            sender,
            coins
        },
        send() {
            const node = NodePool()
            const reAddr = refferrer.value === '' ? ZERO_ADDRESS : refferrer.value
            return node.methods.stakeApp(0, reAddr)
        }
    })
    return {
        refferrer,
        button
    }
}

export function useClaimLp() {
    const {t} = useTranslation()

    const {account} = useWeb3()
    const button = useButton(t('Claim'), {
        send() {
            const app = LpPool()
            return app.methods.claimFor(account)
        }
    })
    return button
}

export function useClaimNode() {
    const {t} = useTranslation()

    const {account} = useWeb3()
    const button = useButton(t('Claim'), {
        send() {
            const app = NodePool()
            return app.methods.claimFor(account)
        }
    })
    return button
}


const INIT_DATA = {
    lpBalance: 0,
    nodeBalance: 0,
}

async function getInitData() {
    // LpPool,
    // NodePool,
    // TokenAll
    const lpPool = LpPool()
    const nodePool = NodePool()
    const tokenAll = TokenAll()
    
    const usdt = tokenAll.erc20("USDT")
    const calls = await multiCalls({
        lpBalance: usdt.methods.balanceOf(lpPool._address),
        nodeBalance: usdt.methods.balanceOf(nodePool._address),
    })
    
    const lpBalance = BN(calls.lpBalance).div(1e6).toString()
    const nodeBalance = BN(calls.nodeBalance).div(1e6).toString()
    // const nodeBalance = await NodeToken().methods.balanceOf(account).call()
    return {
        lpBalance,
        nodeBalance
    }
}

export function useTotalBalance() {
    const {getBlockNumber} = useWeb3()
    const [data, setData] = useState(INIT)
    const blockNubmer = getBlockNumber()

    useEffect(() => {
        // console.log({account, blockNubmer})
        initAsyncData(() => getInitData(), setData)
    },[blockNubmer])
    return {
        ...data,
        // copyLink
    }
}
