
import { memo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

// import NumberScroller from 'react-number-scroller';
import AnimatedNumbers from "react-animated-numbers";

// import {
//     // HashRouter as Router,
//     // Route,
//     // Link,
//     useHistory
// } from "react-router-dom";

import {
    Card,
    Container,
    TextMD,
    TextM,
    TextSM,
    ButtonA,
    WhiteSpace,
    // Input,
    Button,
    Hr,
    Icon,
    TextLinear,
    FlexBlock,
    Progress,
    // Hot,
    Tooltip,
    // APR,
    Input,
    Tips,
    ImgIcon,
    Hot
} from '../../components'

import Approve from '../../contractComponents/Approve';

import { useDialog } from '../../hook';
import useDrawer from '../../hook/useDrawer'

// import SvgCircle from '../../pageComponents/SvgCircle'


// import StakeSeed from './StakeSeed'
// import StakeDialog from '../StakeDialog'

import useBalance from '../../hook/pages/useBalance'
// import {useAppointment, useAppClaim} from '../../hook/pages/useAppointment'
// import {useNode, useTotalBalance} from '../../hook/pages/useNode'

import { wFormatInt, splitNumber, BN } from 'web3-sam/dist/BigNumber';
import {utils} from 'web3-sam'
import useCopy from '../../hook/useCopy';
// import {getAmountIn} from "../../utils/swap"


// import SvgCircle,{CircleArr} from '../../pageComponents/SvgCircle'

// import {useSmiCountdown} from '../../hook/useCountdown'

import {
    useApplyNode,
    useApply,
    useV2Data,
    useClaim
    // useClaimF1, 
} from '../../hook/pages/useV2'

const { shortAddress } = utils

function Claim() {
    return <Button size='0' {...useClaim()}/>
}
const ClaimMome = memo(Claim)

// function ClaimF2() {
//     return <Button size='0' {...useClaimF2()}/>
// }
// const ClaimF2Mome = memo(ClaimF2)


function Home() {

    // const bNode = useTotalBalance()
    const {t} = useTranslation()
    
    const bal = useBalance()
    const oracle = bal.oracle(["AIX","USDT"])
    const {integer, decimalTail, decimal0} = splitNumber(oracle.price)
    
    const swapTo = `https://arbswap.io/swap?inputCurrency=${bal.token("USDT")[0]}&chainIn=42161&outputCurrency=${bal.token("AIX")[0]}&chainOut=42161`
    const v2 = useV2Data()
    // console.log('v2', v2)
    return (
        <Container>
            <FlexBlock flex>
                <div decorate='1' style={{textAlign: 'left'}}>
                    <Tooltip
                        title={
                            <>
                                <TextSM style={{marginBottom: "6px"}}>AIX {wFormatInt(oracle.lp[0][0])}</TextSM>
                                <br />
                                <TextSM>USDT {wFormatInt(oracle.lp[0][1])}</TextSM>
                            </>
                        }
                        placement="right"
                        arrow
                    >
                        <TextSM>AIX {t("Price")}. <Icon size='12' type='icon-info-fill' /></TextSM>
                    </Tooltip> 
                    <br />
                    <TextLinear size='3'>{wFormatInt(integer)}.{decimal0 !== 0 ? <sup style={{fontSize: '20px'}}>{decimal0}</sup> : null }{(decimalTail.slice(0,4))}</TextLinear>
                </div>
                <div>
                    <Button style={{marginRight:'8px'}} status='2' size='0' onClick={() => bal.copyLink('appointment')}>{t("Share")} {t("Link")}</Button>
                    <ButtonA size='0' href={swapTo}>
                        {t("Swap")}
                    </ButtonA>
                </div>
            </FlexBlock>
            {/* <div style={{textAlign: 'left'}}>
                <TextGL>AI ETH</TextGL>
                <br />
                <TextSM color='2'>Joint mining to earn Cake.</TextSM>
            </div>
            <WhiteSpace size='2'/> */}
            <WhiteSpace />
            <Appoint v2={v2}/>
            <WhiteSpace />
            <AppointmentStake v2={v2}/>
            <WhiteSpace />
            <PersonTree person={v2.personChildren}/>
            <WhiteSpace />
            <PublicTree publicTree={v2.publicTree}/>
        </Container>
    )
}
export default Home

const NumberRoll = memo(({num}) => (
    <AnimatedNumbers
        includeComma
        animateToNumber={num}
        fontStyle={{ fontSize: 40, color: "rgb(255, 84, 0)", fontWeight: "800" }}
        locale="en-US"
        configs={[
            { mass: 1, tension: 220, friction: 100 },
            { mass: 1, tension: 180, friction: 130 },
            { mass: 1, tension: 280, friction: 90 },
            { mass: 1, tension: 180, friction: 135 },
            { mass: 1, tension: 260, friction: 100 },
            { mass: 1, tension: 210, friction: 180 },
        ]}
    ></AnimatedNumbers>
))

function Appoint({v2}) {
    // const [number, setNumber] = useState(100.321);
    const {t} = useTranslation()

    return (
        <Card>
            {/* <TextSM onClick={() => setNumber(v => v + 93)}>Appointment</TextSM>
            <br />
            <TextLinear>0</TextLinear><TextSM color='2' style={{marginLeft: '6px'}}> USDT</TextSM>
            <WhiteSpace />
            <NumberScroller
                to={number} timeout={1000}
                decimalPlaces={2}
                step={3}
                numberStyle={{fontSize: '20px'}}
            /> */}
            <div style={{textAlign: 'center', width: '100%'}}>
                <TextM b={800}>{t("Planet Node Reserve")}</TextM>
                {/* <br /> */}
                <div style={{width: "min-content", margin: "auto"}}>
                    <NumberRoll num={v2.nodeReserve} />
                </div>
                
                {/* <TextLinear size='3' linear='1'>{v2.nodeReserve}</TextLinear> */}
                {/* <br /> */}
                <TextSM color='2'>{t("Apply Node to get Total Public yield.")}</TextSM>
            </div>
            <WhiteSpace />
            <Planet v2={v2} />
        </Card>
    )
}

function Planet({v2}) {
    const {
        buttonGalaxy,
        buttonSun,
        buttonEarth
    } = useApplyNode()
    const buttonCall = {
        Galaxy: buttonGalaxy,
        Sun: buttonSun,
        Earth: buttonEarth
    }
    const {t} = useTranslation()

    // console.log(
    //     v2
    // )

    return (
        <FlexBlock flex>
            {
                [
                    // {type: "Galaxy", surplus: 27 - v2.galaxyCount, count: v2.galaxyCount, need: 70000, applied: v2.galaxyBalance !== '0'},
                    // {type: "Sun", surplus: 81 - v2.sunCount, count: v2.sunCount, need: 30000, applied: v2.sunCount !== '0'},
                    // {type: "Earth", surplus: 243 - v2.earthCount, count: v2.earthCount, need: 10000, applied: v2.earthCount !== '0'},
                    {type: "Galaxy", surplus: v2.galaxyCount * 1 + 1, count: v2.galaxyCount, need: 70000, applied: v2.galaxyBalance !== '0'},
                    {type: "Sun", surplus: v2.sunCount * 1 + 1, count: v2.sunCount, need: 30000, applied: v2.galaxyBalance !== '0'},
                    {type: "Earth", surplus: v2.earthCount * 1 + 1, count: v2.earthCount, need: 10000, applied: v2.galaxyBalance !== '0'},
                
                ].map(v => (
                    <PlanetBlock key={v.type} applied={v.applied}>
                        {/* <div style={{filter: v.applied ? "grayscale(0%)": "grayscale(100%)" }}> */}
                        <div style={{filter: v.applied ? "blur(0px)": "grayscale(100%) blur(4px)" }}>
                            <ImgIcon src={`/icon/${v.type}.png`}/>
                            <br />
                            <TextM style={{marginTop: "6px"}}  b={800}>{t(v.type)} <AppliedPlanet type={v.type}>{v.count}</AppliedPlanet></TextM>
                            <br />
                            {/* <AppliedPlanet type={v.type}>{v.count}</AppliedPlanet>
                            <br /> */}
                            <Approve size='0' status='2' {...buttonCall[v.type]} />
                        </div>
                        {
                            !v.applied &&
                            <ApplyPlanet>
                                <div>
                                    <TextM b={800}>{t(v.type)}</TextM>
                                    <br />
                                    <TextSM style={{marginBottom:'3px'}} color='2'>{v.need} U</TextSM>
                                    <br />
                                    {/* <TextSM style={{marginTop: '4px'}}>{t("Surplus")} {v.surplus}</TextSM> */}
                                    <TextSM style={{marginTop: '4px'}}>{t("Applied")} {v.surplus}</TextSM>
                                    <br />
                                    {
                                        v.full ? <Button size='0' disabled>{t("Full")}</Button> : <Approve size='0' status='2' {...buttonCall[v.type]} />
                                    }
                                </div>
                            </ApplyPlanet>
                        }
                    </PlanetBlock>
                ))
            }
        </FlexBlock>
    )
}

function AppointmentStake({v2}) {
    const {t} = useTranslation()
    const {open} = useDialog(<ApplyAppoint isBind={v2.isBind}/>)
    return (
        <Card>
            <TextM b={800}>{t("Appointment AIX Balance")}</TextM>
            <br />
            <TextSM color='2'>{t("Participating can yield x3 returns.")}</TextSM>
            <WhiteSpace />
            <Hr />
            <WhiteSpace />
            <FlexBlock flex>
                <div>
                    <TextSM color='2'>{t("Share")} {t("Reward")}</TextSM>
                    <br />
                    <TextM>{v2.claim} <TextSM color='2'>/ USDT</TextSM></TextM>
                    <br />
                    <TextSM color='2'>{t("Total")} {t("Earned")} <TextSM>{v2.claimedU}</TextSM> USDT</TextSM>
                </div>
                {/* <Button size='0'>{t("Claim")}</Button> */}
                <ClaimMome />
            </FlexBlock>
            <WhiteSpace />
            <Hr />
            <WhiteSpace />
            <FlexBlock flex>
                <div>
                    <TextSM color='2'>{t("Appointment Balance")}</TextSM>
                    <br />
                    <TextM>{wFormatInt(v2.aixBalance)} <TextSM color='2'> AIX</TextSM></TextM>
                    <br />
                    <TextSM color='2'>{t("Spent")} <TextSM>{wFormatInt(v2.spentU)}</TextSM> USDT</TextSM>
                </div>
                <Button size='0' status='2' onClick={open}>{t("Appoint")}</Button>
            </FlexBlock>
            <WhiteSpace />
            <Hr />
            <WhiteSpace />
            <FlexBlock flex>
                <TextSM color="2">{t("Node")} {t("Earned")}</TextSM>
                <TextSM>{wFormatInt(v2.nodeClaim)} U</TextSM>
            </FlexBlock>
            <FlexBlock flex>
                <TextSM color="2">{t("Person")} {t("Earned")}</TextSM>
                <TextSM>{wFormatInt(v2.personClaim)} U</TextSM>
            </FlexBlock>
            <FlexBlock flex>
                <TextSM color="2">{t("Public")} {t("Earned")}</TextSM>
                <TextSM>{wFormatInt(v2.publicCliam)} U</TextSM>
            </FlexBlock>
        </Card>
    )
}

function ApplyAppoint({isBind = false}) {
    const {t} = useTranslation()
    const {
        recommender,
        button,
        button1500
    } = useApply()
    return (
        <Card>
            <TextM b={800}>{t("Appointment AIX Balance")}</TextM>
            <br />
            <TextSM color='2'>{t("Participating can yield x3 returns.")}</TextSM>
            <WhiteSpace />
            <Hr />
            <WhiteSpace />
            <TextSM color='2'><TextSM>150</TextSM> USDT {t("get")} <TextSM>150,000,000</TextSM> AIX</TextSM>
            <br />
            <TextSM color='2'><TextSM>1500</TextSM> USDT {t("get")} <TextSM>1,500,000,000</TextSM> AIX</TextSM>
            <WhiteSpace />
            {
                !isBind &&
                <>
                    <TextSM >{t('Recommender')}</TextSM>
                    <WhiteSpace style={{height: ".6rem"}} />
                    <Input
                        input={{
                            ...recommender,
                            // disabled: app.applied
                        }}
                    />
                    <WhiteSpace />
                </>
            }
            <FlexBlock flex>
                <Approve status='0' w='48' {...button} />
                <Approve status='2' w='48' {...button1500} />
            </FlexBlock>
        </Card>
    )
}


function PersonTree({person = []}) {
    const {t} = useTranslation()
    const pCount = person.length
    const {open} = useDrawer(<PersonChildren person={person} />)
    return (
        <Card onClick={open}>
            <FlexBlock flex>
                <TextM>{t("Person")} {t("Children")}</TextM>
                <Button size='0'>{pCount}</Button>
            </FlexBlock>
        </Card>
    )
}

function PersonChildren({person}) {
    const {t} = useTranslation()
    return (
        <Card>
            <TextM>{t("Person")} {t("Tree")}</TextM>
            <WhiteSpace />
            <Hr />
            {
                person.map((v) => (
                    <PersonTreeBlock
                        key={v.addr}
                        user={v.addr}
                        childList={v.children.map(v => v.addr)}
                    />
                ))
            }
        </Card>
    )
}

function PersonTreeBlock({user, childList = []}) {
    const copy = useCopy()
    return (
        <TreeBlock>
            <Address addr={user} onClick={() => copy(user)} />
            {/* <div style={{}}> */}
            {
                childList.map(v => (
                    <div key={v}>
                        <TextSM style={{margin: "8px 10px 0px"}} color="2">-</TextSM>
                        <Address addr={v} onClick={() => copy(v)} />
                    </div>
                ))
            }
        </TreeBlock>
    )
}

function PublicTree({publicTree}) {
    const {t} = useTranslation()
    const {open} = useDrawer(<PublicTreeDetail publicTree={publicTree} />)
    return (
        <Card onClick={open}>
            <FlexBlock flex>
                {/* <div style={{display:'flex', alignItems:'center'}}>
                    <TextM>Public Level</TextM>
                    <Hot style={{marginLeft: '4px'}}>{publicTree.children.length}</Hot>
                </div> */}
                <TextM>{t("Public")} {t("Level")}</TextM>
                <Button status='2' size='0'>{publicTree.children.length}</Button>
            </FlexBlock>
        </Card>
    )
}

function PublicTreeDetail({publicTree}) {
    const {t} = useTranslation()
    const copy = useCopy()
    return (
        <Card>
            <TextM>{t("Public")} {t("Tree")}</TextM>
            <WhiteSpace />
            <Hr />
            <WhiteSpace />
            <TextM>{t("Branch")} {t("Position")}</TextM>
            <br />
            <TextSM color='2'>{t("Level")}</TextSM><TextSM style={{marginLeft: '6px'}}>{publicTree.level}</TextSM>
            <TextM style={{margin: '0px 6px'}}>·</TextM>
            <TextSM color='2'>{t("NO")}.</TextSM><TextSM style={{marginLeft: '6px'}}>{publicTree.no}</TextSM>
            <WhiteSpace />
            <TextM>{t("Referrer")}</TextM>
            <br />
            <Address addr={publicTree.referrer} onClick={() => copy(publicTree.referrer)} />
            <WhiteSpace />
            <TextM>{t("Children")}</TextM>
            <br />
            <TreeBlock>
                {
                    publicTree.children.map(v => (
                        <Address key={v.addr} addr={v.addr} onClick={() => copy(v.addr)} />
                    ))
                }
            </TreeBlock>
        </Card>
    )
}

function Address({addr, ...other}) {
    return (
        <TextSM {...other}>{shortAddress(addr, 10)} <ImgIcon src="/icon/copy.png"/></TextSM>
    )
}

const PlanetBlock = styled.div`
    /* flex: 1; */
    position: relative;
    width: 32%;
    text-align: center;
    /* border: 1px solid #fff; */
    border-radius: 20px;
    background-color: rgba(0,0,0,.8);
    padding: 14px 0;
    overflow: hidden;
    /* ${p => !p.applied ? "filter: grayscale(100%);": ""} */
    ${ImgIcon} {
        width: 40%;
    }
`

const ApplyPlanet = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, .6);
`

const A_TYPE = {
    Galaxy: "#7e49a0",
    Sun: "#fc5539",
    Earth: "#0676ee"
}
const AppliedPlanet = styled.span`
    /* margin-top: 8px; */
    padding:0px 8px;
    background: ${p => A_TYPE[p.type || "Galaxy"]};
    /* width: 70%; */
    /* margin: 8px auto 0px; */
    border-radius: 10px;
    font-size: 10px;
`

// const CText = styled(TextM)`
//     position: absolute;
//     text-align: center;
//     line-height: 40px;
//     width: 80%;
//     top: 50%;
//     left: 10%;
//     margin-top: -22px;
// `

const TreeBlock = styled.div`
    border-radius: 10px;
    background: rgba(0,0,0,.8);
    width: 100%;
    padding: 8px;
    margin-top: 10px;
`