// Relation
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from 'react-i18next';

// import copy from 'copy-to-clipboard'

import {
    V2,
    TEST_U,
    USDT as Usdt
} from "../../contract/contract";

import { useWeb3, initWeb3, BN, multiCalls, multiCallArr, SendOn, utils, ZERO_ADDRESS , SendLocalOn} from '../../web3'


import {getUrlParams} from '../../utils'

import useBalance from './useBalance'


import initAsyncData from '../initAsyncData'

import useInput from '../useInput'
import useButton from "./useButton"


const INIT_DATA = {
    nodeReserve: 0,
    galaxyCount: 0,
    sunCount: 0,
    earthCount: 0,

    // personal claim
    claim: 0,
    claimedU: 0,
    nodeClaim: 0,
    personClaim: 0,
    publicCliam: 0,
    // personal node
    // uint galaxyNodeBalance;
    // uint sunNodeBalance;
    // uint earthNodeBalance;
    galaxyBalance: 0,
    sunBalance: 0,
    earthBalance: 0,
    // personal stake
    aixBalance: 0,
    spentU: 0,
    parent: ZERO_ADDRESS,
    isBind: false,
    personChildren: [],
    publicTree: {
        level: 0,
        no: 0,
        referrer: ZERO_ADDRESS,
        children: []
    }
}

// function totalNodeEarnedPapers() external view returns(uint);
// function totalEarnedUsdtNode() external view returns(uint);
// function totalClaimedUsdtNode() external view returns(uint);
// function totalNodeBalance() external view returns(uint);
// function galaxyCount() external view returns(uint8);
// function sunCount() external view returns(uint8);
// function earthCount() external view returns(uint8);
// function userTreeOf(address user) external view returns(Branch memory);
// function applyNode(NodeType Type) external;
// function applyAppointment(uint usdtAmount, address referrer) external;
// function publicTreeChildrenOf(address user) external view returns(address[] memory children, UserPosition[] memory pos);
// function personTreeChildrenOf(address user) external view returns(address[] memory children, UserPosition[] memory pos);
// function claimFor(address user) external returns(uint earned, UserPosition memory pos);
async function init(account) {
    const v2 = V2()
    const calls = await multiCalls({
        nodeReserve: v2.methods.totalNodeEarnedPapers(),
        galaxyCount: v2.methods.galaxyCount(),
        sunCount: v2.methods.sunCount(),
        earthCount: v2.methods.earthCount(),
        claim: v2.methods.claimFor(account),
        personReferre: v2.methods.personReferrerOf(account),
        personChildren: v2.methods.personTreeChildrenOf(account),
        publicTree: v2.methods.publicTreeChildrenOf(account),
        publicBranch: v2.methods.userTreeOf(account),
    })

    const perChildrenCalls = await multiCallArr(calls.personChildren.children.map(v => v2.methods.personTreeChildrenOf(v)))
    // console.log('calls', calls)

    const nodeReserve = BN(calls.nodeReserve).dividedBy(1e6).toString(10)
    const galaxyCount = calls.galaxyCount
    const sunCount = calls.sunCount
    const earthCount = calls.earthCount

    const claim = BN(calls.claim.earned).dividedBy(1e6).toString(10)
    const claimedU = BN(calls.claim.pos.claimed).dividedBy(1e6).toString(10)
    const nodeClaim = BN(calls.claim.pos.earnedNodeUsdt).dividedBy(1e6).toString(10)
    const personClaim = BN(calls.claim.pos.earnedShareUsdt).dividedBy(1e6).toString(10)
    const publicCliam = BN(calls.claim.pos.earnedPublicShareUsdt).dividedBy(1e6).toString(10)

    const galaxyBalance = BN(calls.claim.pos.galaxyNodeBalance).dividedBy(1e6).toString(10)
    const sunBalance = BN(calls.claim.pos.sunNodeBalance).dividedBy(1e6).toString(10)
    const earthBalance = BN(calls.claim.pos.earthNodeBalance).dividedBy(1e6).toString(10)

    const aixBalance = BN(calls.claim.pos.appointmentAIX).dividedBy(1e6).toString(10)
    const spentU = BN(calls.claim.pos.appointmentUSDT).dividedBy(1e6).toString(10)

    const parent = calls.personReferre
    const isBind = parent !== ZERO_ADDRESS
    const personChildren = calls.personChildren.children.map((v, i) => {
        const pos = calls.personChildren.pos[i]
        const perChildren = perChildrenCalls[i]
        const children = perChildren.children.map((v, i) => {
            const pos = perChildren.pos[i]
            return {
                addr: v,
                aix: BN(pos.appointmentAIX).dividedBy(1e6).toString(10),
                usdt: BN(pos.appointmentUSDT).dividedBy(1e6).toString(10),
            }
        })
        return {
            addr: v,
            aix: BN(pos.appointmentAIX).dividedBy(1e6).toString(10),
            usdt: BN(pos.appointmentUSDT).dividedBy(1e6).toString(10),
            children
        }
    })
    const publicTree = {}
    publicTree.level = calls.publicBranch.row
    publicTree.no = calls.publicBranch.col * 1 + 1
    publicTree.referrer = calls.publicBranch.referrer
    publicTree.children = calls.publicTree.children.map((v, i) => {
        const pos = calls.publicTree.pos[i]
        return {
            addr: v,
            aix: BN(pos.appointmentAIX).dividedBy(1e6).toString(10),
            usdt: BN(pos.appointmentUSDT).dividedBy(1e6).toString(10),
        }
    })//calls.publicBranch.children

    return {
        nodeReserve,
        galaxyCount,
        sunCount,
        earthCount,
        claim,
        claimedU,
        nodeClaim,
        personClaim,
        publicCliam,
        galaxyBalance,
        sunBalance,
        earthBalance,
        aixBalance,
        spentU,
        parent,
        isBind,
        personChildren,
        publicTree
    }

}

export function useV2Data() {
    const {account, getBlockNumber} = useWeb3()
    const [data, setData] = useState(INIT_DATA)
    const blockNubmer = getBlockNumber()

    useEffect(() => {
        initAsyncData(() => init(account), setData)
    },[account, blockNubmer])
    return {
        ...data,
        // copyLink
    }
}

export function useApply() {
    const {t} = useTranslation()
    const recommender = useInput('')

    const {
        sender,
        coins
    } = useMemo(() => {
        const USDT = TEST_U()
        const sender = V2()._address
        const coins = [
            [...USDT, 10000, true]
        ]
        return {
            sender,
            coins
        }
    },[])
    // applyAppointment(uint usdtAmount, address referrer)
    const button = useButton(t('Appoint 150 U'), {
        approve: {
            sender,
            coins
        },
        send() {
            const app = V2()
            const recom = recommender.value === '' ? ZERO_ADDRESS : recommender.value
            const depositWei = BN(150).multipliedBy(1e6).dp(0,1).toString()
            return app.methods.applyAppointment(depositWei, recom)
        }
    })

    const button1500 = useButton(t('Appoint 1500 U'), {
        approve: {
            sender,
            coins
        },
        send() {
            const app = V2()
            const recom = recommender.value === '' ? ZERO_ADDRESS : recommender.value
            const depositWei = BN(1500).multipliedBy(1e6).dp(0,1).toString()
            return app.methods.applyAppointment(depositWei, recom)
        }
    })

    return {
        recommender,
        button,
        button1500
    }
}

export function useApplyNode() {
    // applyNode(NodeType Type)
    const {t} = useTranslation()
    const {
        sender,
        coins
    } = useMemo(() => {
        const USDT = TEST_U()
        const sender = V2()._address
        const coins = [
            [...USDT, 10000, true]
        ]
        return {
            sender,
            coins
        }
    },[])
    // applyAppointment(uint usdtAmount, address referrer)
    const buttonGalaxy = useButton(t('Apply'), {
        approve: {
            sender,
            coins
        },
        send() {
            const app = V2()
            return app.methods.applyNode(0)
        }
    })

    const buttonSun = useButton(t('Apply'), {
        approve: {
            sender,
            coins
        },
        send() {
            const app = V2()
            return app.methods.applyNode(1)
        }
    })

    const buttonEarth = useButton(t('Apply'), {
        approve: {
            sender,
            coins
        },
        send() {
            const app = V2()
            return app.methods.applyNode(2)
        }
    })

    return {
        buttonGalaxy,
        buttonSun,
        buttonEarth
    }
}

export function useClaim() {
    const {account} = useWeb3()
    const {t} = useTranslation()
    const button = useButton(t("Claim"), {
        send() {
            const app = V2()
            return app.methods.claimFor(account)
        }
    })
    return button
}